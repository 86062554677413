import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TopBarComponent } from './top-bar/top-bar.component';
import { MainPageComponent } from './main-page/main-page.component';
import { RailStationDetailsComponent } from './rail-station-details/rail-station-details.component';
import { pipes } from './station.pipe';

@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    RailStationDetailsComponent,
    TopBarComponent,
    ...pipes,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // UI
    FlexLayoutModule,
    MatGridListModule,
    MatTableModule,
    MatToolbarModule,
    // Router
    RouterModule.forRoot([
      { path: '', component: MainPageComponent },
      { path: 'station/:stationId', component: RailStationDetailsComponent },
    ])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
