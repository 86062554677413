import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { railstn as protos } from '../../../proto/pbjs';

@Injectable({
  providedIn: 'root'
})
export class DataApiService {
  // readonly apiPrefix = 'http://localhost:3000/api/';
  readonly apiPrefix = 'https://api.railstn.org/api/';

  constructor(private http: HttpClient) { }

  getRailStation(pathId: string): Observable<protos.RailStation> {
    return this.http.get(this.apiPrefix + 'station/' + pathId, {
      responseType: 'text',
    })
      .pipe(map((httpResp: string) => {
        const buffer = Uint8Array.from(atob(httpResp), c => c.charCodeAt(0));
        const resp = protos.GetRailStationResponse.decode(buffer);
        if (resp.status !== protos.GetRailStationResponse.Status.SUCCESS) {
          throw new Error('Get error ' + resp.status.toString());
        }
        return resp.railStation as protos.RailStation;
      }));
  }
}
