import { Pipe, PipeTransform, Type } from '@angular/core';

import { railstn as protos } from '../../../proto/pbjs';

@Pipe({ name: 'rsLocalName' })
export class RsLocalName implements PipeTransform {
  transform(station: protos.RailStation): string {
    let localName = station.names
      .find(({ language }) => language === station.localLanguage);
    if (localName == null) {
      if (station.names.length > 0) {
        localName = station.names[0];
      }
    }
    let name = localName?.text;
    if (name == null) {
      name = station.id;
    }
    return name;
  }
}

const pipes: Type<PipeTransform>[] = [RsLocalName];

export { pipes };
