import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { railstn as protos } from '../../../../proto/pbjs';
import { DataApiService } from '../data-api.service';

interface KeyValue {
  key: string;
  value: string;
}

@Component({
  selector: 'app-rail-station-details',
  templateUrl: './rail-station-details.component.html',
  styleUrls: ['./rail-station-details.component.less']
})
export class RailStationDetailsComponent implements OnInit {
  pathId: string | null;
  railStation: Observable<protos.RailStation>;
  railStationData: Observable<KeyValue[]>;
  displayedDataColumns: string[] = ['key', 'value'];

  constructor(
    private route: ActivatedRoute,
    private dataApiService: DataApiService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.pathId = params.get('stationId');
    });
    if (this.pathId != null) {
      this.railStation = this.dataApiService.getRailStation(this.pathId as string);
      this.railStationData = this.railStation.pipe(map((railStation): KeyValue[] => {
        const fields: KeyValue[] = [];
        fields.push({ key: 'uid', value: railStation.uid });
        fields.push({ key: 'id', value: railStation.id });
        fields.push({ key: 'localLanguage', value: railStation.localLanguage });
        railStation.names.forEach((name: protos.LocalizedText) => {
          fields.push({ key: 'name:' + name.language, value: name.text });
        });
        if (railStation.location != null) {
          Object.keys(railStation.location).forEach(key => {
            fields.push({ key: 'location:' + key, value: (railStation.location as object)[key] });
          });
        }
        fields.push({ key: 'operator', value: railStation.operator });
        if (railStation.eid != null) {
          Object.keys(railStation.eid).forEach(key => {
            fields.push({ key: 'eid:' + key, value: (railStation.eid as object)[key] });
          });
        }
        return fields;
      }));
    }
  }
}
