<div fxLayout="column" fxLayoutAlign="space-between" *ngIf="railStation | async; let railStation">
  <h2>{{ railStation | rsLocalName }}</h2>

  <table mat-table [dataSource]="railStationData" class="mat-elevation-z8">
    <tr mat-header-row *matHeaderRowDef="displayedDataColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedDataColumns;"></tr>
    <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef> Property </th>
      <td mat-cell *matCellDef="let element"> {{element.key}} </td>
    </ng-container>
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef> Value </th>
      <td mat-cell *matCellDef="let element"> {{element.value}} </td>
    </ng-container>
  </table>
</div>