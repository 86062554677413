/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.railstn = (function() {

    /**
     * Namespace railstn.
     * @exports railstn
     * @namespace
     */
    var railstn = {};

    railstn.DataService = (function() {

        /**
         * Constructs a new DataService service.
         * @memberof railstn
         * @classdesc Represents a DataService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function DataService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (DataService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = DataService;

        /**
         * Creates new DataService service using the specified rpc implementation.
         * @function create
         * @memberof railstn.DataService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {DataService} RPC service. Useful where requests and/or responses are streamed.
         */
        DataService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link railstn.DataService#getRailStation}.
         * @memberof railstn.DataService
         * @typedef GetRailStationCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {railstn.GetRailStationResponse} [response] GetRailStationResponse
         */

        /**
         * Calls GetRailStation.
         * @function getRailStation
         * @memberof railstn.DataService
         * @instance
         * @param {railstn.IGetRailStationRequest} request GetRailStationRequest message or plain object
         * @param {railstn.DataService.GetRailStationCallback} callback Node-style callback called with the error, if any, and GetRailStationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(DataService.prototype.getRailStation = function getRailStation(request, callback) {
            return this.rpcCall(getRailStation, $root.railstn.GetRailStationRequest, $root.railstn.GetRailStationResponse, request, callback);
        }, "name", { value: "GetRailStation" });

        /**
         * Calls GetRailStation.
         * @function getRailStation
         * @memberof railstn.DataService
         * @instance
         * @param {railstn.IGetRailStationRequest} request GetRailStationRequest message or plain object
         * @returns {Promise<railstn.GetRailStationResponse>} Promise
         * @variation 2
         */

        return DataService;
    })();

    railstn.GetRailStationRequest = (function() {

        /**
         * Properties of a GetRailStationRequest.
         * @memberof railstn
         * @interface IGetRailStationRequest
         * @property {string|null} [uid] GetRailStationRequest uid
         * @property {string|null} [id] GetRailStationRequest id
         * @property {string|null} [uidOrId] GetRailStationRequest uidOrId
         */

        /**
         * Constructs a new GetRailStationRequest.
         * @memberof railstn
         * @classdesc Represents a GetRailStationRequest.
         * @implements IGetRailStationRequest
         * @constructor
         * @param {railstn.IGetRailStationRequest=} [properties] Properties to set
         */
        function GetRailStationRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetRailStationRequest uid.
         * @member {string} uid
         * @memberof railstn.GetRailStationRequest
         * @instance
         */
        GetRailStationRequest.prototype.uid = "";

        /**
         * GetRailStationRequest id.
         * @member {string} id
         * @memberof railstn.GetRailStationRequest
         * @instance
         */
        GetRailStationRequest.prototype.id = "";

        /**
         * GetRailStationRequest uidOrId.
         * @member {string} uidOrId
         * @memberof railstn.GetRailStationRequest
         * @instance
         */
        GetRailStationRequest.prototype.uidOrId = "";

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GetRailStationRequest by.
         * @member {"uid"|"id"|"uidOrId"|undefined} by
         * @memberof railstn.GetRailStationRequest
         * @instance
         */
        Object.defineProperty(GetRailStationRequest.prototype, "by", {
            get: $util.oneOfGetter($oneOfFields = ["uid", "id", "uidOrId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetRailStationRequest instance using the specified properties.
         * @function create
         * @memberof railstn.GetRailStationRequest
         * @static
         * @param {railstn.IGetRailStationRequest=} [properties] Properties to set
         * @returns {railstn.GetRailStationRequest} GetRailStationRequest instance
         */
        GetRailStationRequest.create = function create(properties) {
            return new GetRailStationRequest(properties);
        };

        /**
         * Encodes the specified GetRailStationRequest message. Does not implicitly {@link railstn.GetRailStationRequest.verify|verify} messages.
         * @function encode
         * @memberof railstn.GetRailStationRequest
         * @static
         * @param {railstn.IGetRailStationRequest} message GetRailStationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetRailStationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.id);
            if (message.uidOrId != null && Object.hasOwnProperty.call(message, "uidOrId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.uidOrId);
            return writer;
        };

        /**
         * Encodes the specified GetRailStationRequest message, length delimited. Does not implicitly {@link railstn.GetRailStationRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof railstn.GetRailStationRequest
         * @static
         * @param {railstn.IGetRailStationRequest} message GetRailStationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetRailStationRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetRailStationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof railstn.GetRailStationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {railstn.GetRailStationRequest} GetRailStationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetRailStationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.railstn.GetRailStationRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uid = reader.string();
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                case 3:
                    message.uidOrId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetRailStationRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof railstn.GetRailStationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {railstn.GetRailStationRequest} GetRailStationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetRailStationRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetRailStationRequest message.
         * @function verify
         * @memberof railstn.GetRailStationRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetRailStationRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.uid != null && message.hasOwnProperty("uid")) {
                properties.by = 1;
                if (!$util.isString(message.uid))
                    return "uid: string expected";
            }
            if (message.id != null && message.hasOwnProperty("id")) {
                if (properties.by === 1)
                    return "by: multiple values";
                properties.by = 1;
                if (!$util.isString(message.id))
                    return "id: string expected";
            }
            if (message.uidOrId != null && message.hasOwnProperty("uidOrId")) {
                if (properties.by === 1)
                    return "by: multiple values";
                properties.by = 1;
                if (!$util.isString(message.uidOrId))
                    return "uidOrId: string expected";
            }
            return null;
        };

        /**
         * Creates a GetRailStationRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof railstn.GetRailStationRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {railstn.GetRailStationRequest} GetRailStationRequest
         */
        GetRailStationRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.railstn.GetRailStationRequest)
                return object;
            var message = new $root.railstn.GetRailStationRequest();
            if (object.uid != null)
                message.uid = String(object.uid);
            if (object.id != null)
                message.id = String(object.id);
            if (object.uidOrId != null)
                message.uidOrId = String(object.uidOrId);
            return message;
        };

        /**
         * Creates a plain object from a GetRailStationRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof railstn.GetRailStationRequest
         * @static
         * @param {railstn.GetRailStationRequest} message GetRailStationRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetRailStationRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.uid != null && message.hasOwnProperty("uid")) {
                object.uid = message.uid;
                if (options.oneofs)
                    object.by = "uid";
            }
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object.by = "id";
            }
            if (message.uidOrId != null && message.hasOwnProperty("uidOrId")) {
                object.uidOrId = message.uidOrId;
                if (options.oneofs)
                    object.by = "uidOrId";
            }
            return object;
        };

        /**
         * Converts this GetRailStationRequest to JSON.
         * @function toJSON
         * @memberof railstn.GetRailStationRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetRailStationRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetRailStationRequest;
    })();

    railstn.GetRailStationResponse = (function() {

        /**
         * Properties of a GetRailStationResponse.
         * @memberof railstn
         * @interface IGetRailStationResponse
         * @property {railstn.GetRailStationResponse.Status|null} [status] GetRailStationResponse status
         * @property {railstn.IRailStation|null} [railStation] GetRailStationResponse railStation
         */

        /**
         * Constructs a new GetRailStationResponse.
         * @memberof railstn
         * @classdesc Represents a GetRailStationResponse.
         * @implements IGetRailStationResponse
         * @constructor
         * @param {railstn.IGetRailStationResponse=} [properties] Properties to set
         */
        function GetRailStationResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetRailStationResponse status.
         * @member {railstn.GetRailStationResponse.Status} status
         * @memberof railstn.GetRailStationResponse
         * @instance
         */
        GetRailStationResponse.prototype.status = 0;

        /**
         * GetRailStationResponse railStation.
         * @member {railstn.IRailStation|null|undefined} railStation
         * @memberof railstn.GetRailStationResponse
         * @instance
         */
        GetRailStationResponse.prototype.railStation = null;

        /**
         * Creates a new GetRailStationResponse instance using the specified properties.
         * @function create
         * @memberof railstn.GetRailStationResponse
         * @static
         * @param {railstn.IGetRailStationResponse=} [properties] Properties to set
         * @returns {railstn.GetRailStationResponse} GetRailStationResponse instance
         */
        GetRailStationResponse.create = function create(properties) {
            return new GetRailStationResponse(properties);
        };

        /**
         * Encodes the specified GetRailStationResponse message. Does not implicitly {@link railstn.GetRailStationResponse.verify|verify} messages.
         * @function encode
         * @memberof railstn.GetRailStationResponse
         * @static
         * @param {railstn.IGetRailStationResponse} message GetRailStationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetRailStationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.railStation != null && Object.hasOwnProperty.call(message, "railStation"))
                $root.railstn.RailStation.encode(message.railStation, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetRailStationResponse message, length delimited. Does not implicitly {@link railstn.GetRailStationResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof railstn.GetRailStationResponse
         * @static
         * @param {railstn.IGetRailStationResponse} message GetRailStationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetRailStationResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetRailStationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof railstn.GetRailStationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {railstn.GetRailStationResponse} GetRailStationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetRailStationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.railstn.GetRailStationResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 2:
                    message.railStation = $root.railstn.RailStation.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetRailStationResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof railstn.GetRailStationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {railstn.GetRailStationResponse} GetRailStationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetRailStationResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetRailStationResponse message.
         * @function verify
         * @memberof railstn.GetRailStationResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetRailStationResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.railStation != null && message.hasOwnProperty("railStation")) {
                var error = $root.railstn.RailStation.verify(message.railStation);
                if (error)
                    return "railStation." + error;
            }
            return null;
        };

        /**
         * Creates a GetRailStationResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof railstn.GetRailStationResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {railstn.GetRailStationResponse} GetRailStationResponse
         */
        GetRailStationResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.railstn.GetRailStationResponse)
                return object;
            var message = new $root.railstn.GetRailStationResponse();
            switch (object.status) {
            case "SUCCESS":
            case 0:
                message.status = 0;
                break;
            case "NOT_FOUND":
            case 1:
                message.status = 1;
                break;
            }
            if (object.railStation != null) {
                if (typeof object.railStation !== "object")
                    throw TypeError(".railstn.GetRailStationResponse.railStation: object expected");
                message.railStation = $root.railstn.RailStation.fromObject(object.railStation);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetRailStationResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof railstn.GetRailStationResponse
         * @static
         * @param {railstn.GetRailStationResponse} message GetRailStationResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetRailStationResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "SUCCESS" : 0;
                object.railStation = null;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.railstn.GetRailStationResponse.Status[message.status] : message.status;
            if (message.railStation != null && message.hasOwnProperty("railStation"))
                object.railStation = $root.railstn.RailStation.toObject(message.railStation, options);
            return object;
        };

        /**
         * Converts this GetRailStationResponse to JSON.
         * @function toJSON
         * @memberof railstn.GetRailStationResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetRailStationResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Status enum.
         * @name railstn.GetRailStationResponse.Status
         * @enum {number}
         * @property {number} SUCCESS=0 SUCCESS value
         * @property {number} NOT_FOUND=1 NOT_FOUND value
         */
        GetRailStationResponse.Status = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SUCCESS"] = 0;
            values[valuesById[1] = "NOT_FOUND"] = 1;
            return values;
        })();

        return GetRailStationResponse;
    })();

    railstn.RailStation = (function() {

        /**
         * Properties of a RailStation.
         * @memberof railstn
         * @interface IRailStation
         * @property {string|null} [uid] RailStation uid
         * @property {string|null} [id] RailStation id
         * @property {Array.<railstn.ILocalizedText>|null} [names] RailStation names
         * @property {railstn.ILocation|null} [location] RailStation location
         * @property {string|null} [localLanguage] RailStation localLanguage
         * @property {railstn.IExtentedIDs|null} [eid] RailStation eid
         * @property {string|null} [operator] RailStation operator
         */

        /**
         * Constructs a new RailStation.
         * @memberof railstn
         * @classdesc Represents a RailStation.
         * @implements IRailStation
         * @constructor
         * @param {railstn.IRailStation=} [properties] Properties to set
         */
        function RailStation(properties) {
            this.names = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RailStation uid.
         * @member {string} uid
         * @memberof railstn.RailStation
         * @instance
         */
        RailStation.prototype.uid = "";

        /**
         * RailStation id.
         * @member {string} id
         * @memberof railstn.RailStation
         * @instance
         */
        RailStation.prototype.id = "";

        /**
         * RailStation names.
         * @member {Array.<railstn.ILocalizedText>} names
         * @memberof railstn.RailStation
         * @instance
         */
        RailStation.prototype.names = $util.emptyArray;

        /**
         * RailStation location.
         * @member {railstn.ILocation|null|undefined} location
         * @memberof railstn.RailStation
         * @instance
         */
        RailStation.prototype.location = null;

        /**
         * RailStation localLanguage.
         * @member {string} localLanguage
         * @memberof railstn.RailStation
         * @instance
         */
        RailStation.prototype.localLanguage = "";

        /**
         * RailStation eid.
         * @member {railstn.IExtentedIDs|null|undefined} eid
         * @memberof railstn.RailStation
         * @instance
         */
        RailStation.prototype.eid = null;

        /**
         * RailStation operator.
         * @member {string} operator
         * @memberof railstn.RailStation
         * @instance
         */
        RailStation.prototype.operator = "";

        /**
         * Creates a new RailStation instance using the specified properties.
         * @function create
         * @memberof railstn.RailStation
         * @static
         * @param {railstn.IRailStation=} [properties] Properties to set
         * @returns {railstn.RailStation} RailStation instance
         */
        RailStation.create = function create(properties) {
            return new RailStation(properties);
        };

        /**
         * Encodes the specified RailStation message. Does not implicitly {@link railstn.RailStation.verify|verify} messages.
         * @function encode
         * @memberof railstn.RailStation
         * @static
         * @param {railstn.IRailStation} message RailStation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RailStation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.id);
            if (message.names != null && message.names.length)
                for (var i = 0; i < message.names.length; ++i)
                    $root.railstn.LocalizedText.encode(message.names[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                $root.railstn.Location.encode(message.location, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.localLanguage != null && Object.hasOwnProperty.call(message, "localLanguage"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.localLanguage);
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                $root.railstn.ExtentedIDs.encode(message.eid, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.operator != null && Object.hasOwnProperty.call(message, "operator"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.operator);
            return writer;
        };

        /**
         * Encodes the specified RailStation message, length delimited. Does not implicitly {@link railstn.RailStation.verify|verify} messages.
         * @function encodeDelimited
         * @memberof railstn.RailStation
         * @static
         * @param {railstn.IRailStation} message RailStation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RailStation.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RailStation message from the specified reader or buffer.
         * @function decode
         * @memberof railstn.RailStation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {railstn.RailStation} RailStation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RailStation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.railstn.RailStation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uid = reader.string();
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                case 3:
                    if (!(message.names && message.names.length))
                        message.names = [];
                    message.names.push($root.railstn.LocalizedText.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.location = $root.railstn.Location.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.localLanguage = reader.string();
                    break;
                case 6:
                    message.eid = $root.railstn.ExtentedIDs.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.operator = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RailStation message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof railstn.RailStation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {railstn.RailStation} RailStation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RailStation.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RailStation message.
         * @function verify
         * @memberof railstn.RailStation
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RailStation.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.uid != null && message.hasOwnProperty("uid"))
                if (!$util.isString(message.uid))
                    return "uid: string expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.names != null && message.hasOwnProperty("names")) {
                if (!Array.isArray(message.names))
                    return "names: array expected";
                for (var i = 0; i < message.names.length; ++i) {
                    var error = $root.railstn.LocalizedText.verify(message.names[i]);
                    if (error)
                        return "names." + error;
                }
            }
            if (message.location != null && message.hasOwnProperty("location")) {
                var error = $root.railstn.Location.verify(message.location);
                if (error)
                    return "location." + error;
            }
            if (message.localLanguage != null && message.hasOwnProperty("localLanguage"))
                if (!$util.isString(message.localLanguage))
                    return "localLanguage: string expected";
            if (message.eid != null && message.hasOwnProperty("eid")) {
                var error = $root.railstn.ExtentedIDs.verify(message.eid);
                if (error)
                    return "eid." + error;
            }
            if (message.operator != null && message.hasOwnProperty("operator"))
                if (!$util.isString(message.operator))
                    return "operator: string expected";
            return null;
        };

        /**
         * Creates a RailStation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof railstn.RailStation
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {railstn.RailStation} RailStation
         */
        RailStation.fromObject = function fromObject(object) {
            if (object instanceof $root.railstn.RailStation)
                return object;
            var message = new $root.railstn.RailStation();
            if (object.uid != null)
                message.uid = String(object.uid);
            if (object.id != null)
                message.id = String(object.id);
            if (object.names) {
                if (!Array.isArray(object.names))
                    throw TypeError(".railstn.RailStation.names: array expected");
                message.names = [];
                for (var i = 0; i < object.names.length; ++i) {
                    if (typeof object.names[i] !== "object")
                        throw TypeError(".railstn.RailStation.names: object expected");
                    message.names[i] = $root.railstn.LocalizedText.fromObject(object.names[i]);
                }
            }
            if (object.location != null) {
                if (typeof object.location !== "object")
                    throw TypeError(".railstn.RailStation.location: object expected");
                message.location = $root.railstn.Location.fromObject(object.location);
            }
            if (object.localLanguage != null)
                message.localLanguage = String(object.localLanguage);
            if (object.eid != null) {
                if (typeof object.eid !== "object")
                    throw TypeError(".railstn.RailStation.eid: object expected");
                message.eid = $root.railstn.ExtentedIDs.fromObject(object.eid);
            }
            if (object.operator != null)
                message.operator = String(object.operator);
            return message;
        };

        /**
         * Creates a plain object from a RailStation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof railstn.RailStation
         * @static
         * @param {railstn.RailStation} message RailStation
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RailStation.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.names = [];
            if (options.defaults) {
                object.uid = "";
                object.id = "";
                object.location = null;
                object.localLanguage = "";
                object.eid = null;
                object.operator = "";
            }
            if (message.uid != null && message.hasOwnProperty("uid"))
                object.uid = message.uid;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.names && message.names.length) {
                object.names = [];
                for (var j = 0; j < message.names.length; ++j)
                    object.names[j] = $root.railstn.LocalizedText.toObject(message.names[j], options);
            }
            if (message.location != null && message.hasOwnProperty("location"))
                object.location = $root.railstn.Location.toObject(message.location, options);
            if (message.localLanguage != null && message.hasOwnProperty("localLanguage"))
                object.localLanguage = message.localLanguage;
            if (message.eid != null && message.hasOwnProperty("eid"))
                object.eid = $root.railstn.ExtentedIDs.toObject(message.eid, options);
            if (message.operator != null && message.hasOwnProperty("operator"))
                object.operator = message.operator;
            return object;
        };

        /**
         * Converts this RailStation to JSON.
         * @function toJSON
         * @memberof railstn.RailStation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RailStation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RailStation;
    })();

    railstn.Location = (function() {

        /**
         * Properties of a Location.
         * @memberof railstn
         * @interface ILocation
         * @property {string|null} [latitude] Location latitude
         * @property {string|null} [longitude] Location longitude
         * @property {string|null} [countryRegion] Location countryRegion
         * @property {string|null} [localityCode] Location localityCode
         */

        /**
         * Constructs a new Location.
         * @memberof railstn
         * @classdesc Represents a Location.
         * @implements ILocation
         * @constructor
         * @param {railstn.ILocation=} [properties] Properties to set
         */
        function Location(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Location latitude.
         * @member {string} latitude
         * @memberof railstn.Location
         * @instance
         */
        Location.prototype.latitude = "";

        /**
         * Location longitude.
         * @member {string} longitude
         * @memberof railstn.Location
         * @instance
         */
        Location.prototype.longitude = "";

        /**
         * Location countryRegion.
         * @member {string} countryRegion
         * @memberof railstn.Location
         * @instance
         */
        Location.prototype.countryRegion = "";

        /**
         * Location localityCode.
         * @member {string} localityCode
         * @memberof railstn.Location
         * @instance
         */
        Location.prototype.localityCode = "";

        /**
         * Creates a new Location instance using the specified properties.
         * @function create
         * @memberof railstn.Location
         * @static
         * @param {railstn.ILocation=} [properties] Properties to set
         * @returns {railstn.Location} Location instance
         */
        Location.create = function create(properties) {
            return new Location(properties);
        };

        /**
         * Encodes the specified Location message. Does not implicitly {@link railstn.Location.verify|verify} messages.
         * @function encode
         * @memberof railstn.Location
         * @static
         * @param {railstn.ILocation} message Location message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Location.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.latitude);
            if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.longitude);
            if (message.countryRegion != null && Object.hasOwnProperty.call(message, "countryRegion"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.countryRegion);
            if (message.localityCode != null && Object.hasOwnProperty.call(message, "localityCode"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.localityCode);
            return writer;
        };

        /**
         * Encodes the specified Location message, length delimited. Does not implicitly {@link railstn.Location.verify|verify} messages.
         * @function encodeDelimited
         * @memberof railstn.Location
         * @static
         * @param {railstn.ILocation} message Location message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Location.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Location message from the specified reader or buffer.
         * @function decode
         * @memberof railstn.Location
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {railstn.Location} Location
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Location.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.railstn.Location();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.latitude = reader.string();
                    break;
                case 2:
                    message.longitude = reader.string();
                    break;
                case 3:
                    message.countryRegion = reader.string();
                    break;
                case 4:
                    message.localityCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Location message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof railstn.Location
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {railstn.Location} Location
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Location.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Location message.
         * @function verify
         * @memberof railstn.Location
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Location.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.latitude != null && message.hasOwnProperty("latitude"))
                if (!$util.isString(message.latitude))
                    return "latitude: string expected";
            if (message.longitude != null && message.hasOwnProperty("longitude"))
                if (!$util.isString(message.longitude))
                    return "longitude: string expected";
            if (message.countryRegion != null && message.hasOwnProperty("countryRegion"))
                if (!$util.isString(message.countryRegion))
                    return "countryRegion: string expected";
            if (message.localityCode != null && message.hasOwnProperty("localityCode"))
                if (!$util.isString(message.localityCode))
                    return "localityCode: string expected";
            return null;
        };

        /**
         * Creates a Location message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof railstn.Location
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {railstn.Location} Location
         */
        Location.fromObject = function fromObject(object) {
            if (object instanceof $root.railstn.Location)
                return object;
            var message = new $root.railstn.Location();
            if (object.latitude != null)
                message.latitude = String(object.latitude);
            if (object.longitude != null)
                message.longitude = String(object.longitude);
            if (object.countryRegion != null)
                message.countryRegion = String(object.countryRegion);
            if (object.localityCode != null)
                message.localityCode = String(object.localityCode);
            return message;
        };

        /**
         * Creates a plain object from a Location message. Also converts values to other types if specified.
         * @function toObject
         * @memberof railstn.Location
         * @static
         * @param {railstn.Location} message Location
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Location.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.latitude = "";
                object.longitude = "";
                object.countryRegion = "";
                object.localityCode = "";
            }
            if (message.latitude != null && message.hasOwnProperty("latitude"))
                object.latitude = message.latitude;
            if (message.longitude != null && message.hasOwnProperty("longitude"))
                object.longitude = message.longitude;
            if (message.countryRegion != null && message.hasOwnProperty("countryRegion"))
                object.countryRegion = message.countryRegion;
            if (message.localityCode != null && message.hasOwnProperty("localityCode"))
                object.localityCode = message.localityCode;
            return object;
        };

        /**
         * Converts this Location to JSON.
         * @function toJSON
         * @memberof railstn.Location
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Location.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Location;
    })();

    railstn.LocalizedText = (function() {

        /**
         * Properties of a LocalizedText.
         * @memberof railstn
         * @interface ILocalizedText
         * @property {string|null} [language] LocalizedText language
         * @property {string|null} [text] LocalizedText text
         */

        /**
         * Constructs a new LocalizedText.
         * @memberof railstn
         * @classdesc Represents a LocalizedText.
         * @implements ILocalizedText
         * @constructor
         * @param {railstn.ILocalizedText=} [properties] Properties to set
         */
        function LocalizedText(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocalizedText language.
         * @member {string} language
         * @memberof railstn.LocalizedText
         * @instance
         */
        LocalizedText.prototype.language = "";

        /**
         * LocalizedText text.
         * @member {string} text
         * @memberof railstn.LocalizedText
         * @instance
         */
        LocalizedText.prototype.text = "";

        /**
         * Creates a new LocalizedText instance using the specified properties.
         * @function create
         * @memberof railstn.LocalizedText
         * @static
         * @param {railstn.ILocalizedText=} [properties] Properties to set
         * @returns {railstn.LocalizedText} LocalizedText instance
         */
        LocalizedText.create = function create(properties) {
            return new LocalizedText(properties);
        };

        /**
         * Encodes the specified LocalizedText message. Does not implicitly {@link railstn.LocalizedText.verify|verify} messages.
         * @function encode
         * @memberof railstn.LocalizedText
         * @static
         * @param {railstn.ILocalizedText} message LocalizedText message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocalizedText.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.language != null && Object.hasOwnProperty.call(message, "language"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.language);
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.text);
            return writer;
        };

        /**
         * Encodes the specified LocalizedText message, length delimited. Does not implicitly {@link railstn.LocalizedText.verify|verify} messages.
         * @function encodeDelimited
         * @memberof railstn.LocalizedText
         * @static
         * @param {railstn.ILocalizedText} message LocalizedText message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocalizedText.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocalizedText message from the specified reader or buffer.
         * @function decode
         * @memberof railstn.LocalizedText
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {railstn.LocalizedText} LocalizedText
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocalizedText.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.railstn.LocalizedText();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.language = reader.string();
                    break;
                case 2:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LocalizedText message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof railstn.LocalizedText
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {railstn.LocalizedText} LocalizedText
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocalizedText.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocalizedText message.
         * @function verify
         * @memberof railstn.LocalizedText
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocalizedText.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.language != null && message.hasOwnProperty("language"))
                if (!$util.isString(message.language))
                    return "language: string expected";
            if (message.text != null && message.hasOwnProperty("text"))
                if (!$util.isString(message.text))
                    return "text: string expected";
            return null;
        };

        /**
         * Creates a LocalizedText message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof railstn.LocalizedText
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {railstn.LocalizedText} LocalizedText
         */
        LocalizedText.fromObject = function fromObject(object) {
            if (object instanceof $root.railstn.LocalizedText)
                return object;
            var message = new $root.railstn.LocalizedText();
            if (object.language != null)
                message.language = String(object.language);
            if (object.text != null)
                message.text = String(object.text);
            return message;
        };

        /**
         * Creates a plain object from a LocalizedText message. Also converts values to other types if specified.
         * @function toObject
         * @memberof railstn.LocalizedText
         * @static
         * @param {railstn.LocalizedText} message LocalizedText
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocalizedText.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.language = "";
                object.text = "";
            }
            if (message.language != null && message.hasOwnProperty("language"))
                object.language = message.language;
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = message.text;
            return object;
        };

        /**
         * Converts this LocalizedText to JSON.
         * @function toJSON
         * @memberof railstn.LocalizedText
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocalizedText.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LocalizedText;
    })();

    railstn.ExtentedIDs = (function() {

        /**
         * Properties of an ExtentedIDs.
         * @memberof railstn
         * @interface IExtentedIDs
         * @property {string|null} [cnTelegraphCode] ExtentedIDs cnTelegraphCode
         * @property {string|null} [cnPinyinCode] ExtentedIDs cnPinyinCode
         * @property {string|null} [cnGbT_10302_2010] ExtentedIDs cnGbT_10302_2010
         * @property {string|null} [cnChinaRailwayNameId] ExtentedIDs cnChinaRailwayNameId
         * @property {string|null} [geogvUid] ExtentedIDs geogvUid
         * @property {string|null} [ruRzdExpress3] ExtentedIDs ruRzdExpress3
         */

        /**
         * Constructs a new ExtentedIDs.
         * @memberof railstn
         * @classdesc Represents an ExtentedIDs.
         * @implements IExtentedIDs
         * @constructor
         * @param {railstn.IExtentedIDs=} [properties] Properties to set
         */
        function ExtentedIDs(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExtentedIDs cnTelegraphCode.
         * @member {string} cnTelegraphCode
         * @memberof railstn.ExtentedIDs
         * @instance
         */
        ExtentedIDs.prototype.cnTelegraphCode = "";

        /**
         * ExtentedIDs cnPinyinCode.
         * @member {string} cnPinyinCode
         * @memberof railstn.ExtentedIDs
         * @instance
         */
        ExtentedIDs.prototype.cnPinyinCode = "";

        /**
         * ExtentedIDs cnGbT_10302_2010.
         * @member {string} cnGbT_10302_2010
         * @memberof railstn.ExtentedIDs
         * @instance
         */
        ExtentedIDs.prototype.cnGbT_10302_2010 = "";

        /**
         * ExtentedIDs cnChinaRailwayNameId.
         * @member {string} cnChinaRailwayNameId
         * @memberof railstn.ExtentedIDs
         * @instance
         */
        ExtentedIDs.prototype.cnChinaRailwayNameId = "";

        /**
         * ExtentedIDs geogvUid.
         * @member {string} geogvUid
         * @memberof railstn.ExtentedIDs
         * @instance
         */
        ExtentedIDs.prototype.geogvUid = "";

        /**
         * ExtentedIDs ruRzdExpress3.
         * @member {string} ruRzdExpress3
         * @memberof railstn.ExtentedIDs
         * @instance
         */
        ExtentedIDs.prototype.ruRzdExpress3 = "";

        /**
         * Creates a new ExtentedIDs instance using the specified properties.
         * @function create
         * @memberof railstn.ExtentedIDs
         * @static
         * @param {railstn.IExtentedIDs=} [properties] Properties to set
         * @returns {railstn.ExtentedIDs} ExtentedIDs instance
         */
        ExtentedIDs.create = function create(properties) {
            return new ExtentedIDs(properties);
        };

        /**
         * Encodes the specified ExtentedIDs message. Does not implicitly {@link railstn.ExtentedIDs.verify|verify} messages.
         * @function encode
         * @memberof railstn.ExtentedIDs
         * @static
         * @param {railstn.IExtentedIDs} message ExtentedIDs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExtentedIDs.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cnTelegraphCode != null && Object.hasOwnProperty.call(message, "cnTelegraphCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.cnTelegraphCode);
            if (message.cnPinyinCode != null && Object.hasOwnProperty.call(message, "cnPinyinCode"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.cnPinyinCode);
            if (message.cnGbT_10302_2010 != null && Object.hasOwnProperty.call(message, "cnGbT_10302_2010"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.cnGbT_10302_2010);
            if (message.geogvUid != null && Object.hasOwnProperty.call(message, "geogvUid"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.geogvUid);
            if (message.ruRzdExpress3 != null && Object.hasOwnProperty.call(message, "ruRzdExpress3"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.ruRzdExpress3);
            if (message.cnChinaRailwayNameId != null && Object.hasOwnProperty.call(message, "cnChinaRailwayNameId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.cnChinaRailwayNameId);
            return writer;
        };

        /**
         * Encodes the specified ExtentedIDs message, length delimited. Does not implicitly {@link railstn.ExtentedIDs.verify|verify} messages.
         * @function encodeDelimited
         * @memberof railstn.ExtentedIDs
         * @static
         * @param {railstn.IExtentedIDs} message ExtentedIDs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExtentedIDs.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExtentedIDs message from the specified reader or buffer.
         * @function decode
         * @memberof railstn.ExtentedIDs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {railstn.ExtentedIDs} ExtentedIDs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExtentedIDs.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.railstn.ExtentedIDs();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.cnTelegraphCode = reader.string();
                    break;
                case 2:
                    message.cnPinyinCode = reader.string();
                    break;
                case 3:
                    message.cnGbT_10302_2010 = reader.string();
                    break;
                case 6:
                    message.cnChinaRailwayNameId = reader.string();
                    break;
                case 4:
                    message.geogvUid = reader.string();
                    break;
                case 5:
                    message.ruRzdExpress3 = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExtentedIDs message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof railstn.ExtentedIDs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {railstn.ExtentedIDs} ExtentedIDs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExtentedIDs.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExtentedIDs message.
         * @function verify
         * @memberof railstn.ExtentedIDs
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExtentedIDs.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.cnTelegraphCode != null && message.hasOwnProperty("cnTelegraphCode"))
                if (!$util.isString(message.cnTelegraphCode))
                    return "cnTelegraphCode: string expected";
            if (message.cnPinyinCode != null && message.hasOwnProperty("cnPinyinCode"))
                if (!$util.isString(message.cnPinyinCode))
                    return "cnPinyinCode: string expected";
            if (message.cnGbT_10302_2010 != null && message.hasOwnProperty("cnGbT_10302_2010"))
                if (!$util.isString(message.cnGbT_10302_2010))
                    return "cnGbT_10302_2010: string expected";
            if (message.cnChinaRailwayNameId != null && message.hasOwnProperty("cnChinaRailwayNameId"))
                if (!$util.isString(message.cnChinaRailwayNameId))
                    return "cnChinaRailwayNameId: string expected";
            if (message.geogvUid != null && message.hasOwnProperty("geogvUid"))
                if (!$util.isString(message.geogvUid))
                    return "geogvUid: string expected";
            if (message.ruRzdExpress3 != null && message.hasOwnProperty("ruRzdExpress3"))
                if (!$util.isString(message.ruRzdExpress3))
                    return "ruRzdExpress3: string expected";
            return null;
        };

        /**
         * Creates an ExtentedIDs message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof railstn.ExtentedIDs
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {railstn.ExtentedIDs} ExtentedIDs
         */
        ExtentedIDs.fromObject = function fromObject(object) {
            if (object instanceof $root.railstn.ExtentedIDs)
                return object;
            var message = new $root.railstn.ExtentedIDs();
            if (object.cnTelegraphCode != null)
                message.cnTelegraphCode = String(object.cnTelegraphCode);
            if (object.cnPinyinCode != null)
                message.cnPinyinCode = String(object.cnPinyinCode);
            if (object.cnGbT_10302_2010 != null)
                message.cnGbT_10302_2010 = String(object.cnGbT_10302_2010);
            if (object.cnChinaRailwayNameId != null)
                message.cnChinaRailwayNameId = String(object.cnChinaRailwayNameId);
            if (object.geogvUid != null)
                message.geogvUid = String(object.geogvUid);
            if (object.ruRzdExpress3 != null)
                message.ruRzdExpress3 = String(object.ruRzdExpress3);
            return message;
        };

        /**
         * Creates a plain object from an ExtentedIDs message. Also converts values to other types if specified.
         * @function toObject
         * @memberof railstn.ExtentedIDs
         * @static
         * @param {railstn.ExtentedIDs} message ExtentedIDs
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExtentedIDs.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.cnTelegraphCode = "";
                object.cnPinyinCode = "";
                object.cnGbT_10302_2010 = "";
                object.geogvUid = "";
                object.ruRzdExpress3 = "";
                object.cnChinaRailwayNameId = "";
            }
            if (message.cnTelegraphCode != null && message.hasOwnProperty("cnTelegraphCode"))
                object.cnTelegraphCode = message.cnTelegraphCode;
            if (message.cnPinyinCode != null && message.hasOwnProperty("cnPinyinCode"))
                object.cnPinyinCode = message.cnPinyinCode;
            if (message.cnGbT_10302_2010 != null && message.hasOwnProperty("cnGbT_10302_2010"))
                object.cnGbT_10302_2010 = message.cnGbT_10302_2010;
            if (message.geogvUid != null && message.hasOwnProperty("geogvUid"))
                object.geogvUid = message.geogvUid;
            if (message.ruRzdExpress3 != null && message.hasOwnProperty("ruRzdExpress3"))
                object.ruRzdExpress3 = message.ruRzdExpress3;
            if (message.cnChinaRailwayNameId != null && message.hasOwnProperty("cnChinaRailwayNameId"))
                object.cnChinaRailwayNameId = message.cnChinaRailwayNameId;
            return object;
        };

        /**
         * Converts this ExtentedIDs to JSON.
         * @function toJSON
         * @memberof railstn.ExtentedIDs
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExtentedIDs.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ExtentedIDs;
    })();

    return railstn;
})();

module.exports = $root;
